.betslip {
    display: flex;
    flex-direction: column;
    position: fixed;
    top: 62px;
    bottom: 0;
    right: 0;
    width: 312px;

    background: #fff;
    box-shadow: 1px 0 20px rgba(0, 0, 0, 0.08);

    & .header {
        flex-shrink: 0;
        align-items: center;
        display: flex;
        height: 50px;
        justify-content: space-between;
        padding: 0 .25rem;
        position: relative;

        background-color: #1b4b72;
        color: #fff;
    }

    & .body {
        flex-grow: 1;
        height: 100%;
        overflow: auto;

        & .input-value {
            width: 74px;
            height: 36px;

            &:focus {
                border: none;
                outline: none;
            }
        }

        & .buttons-group {
            display: flex;
            flex-direction: column;

            & button {
                display: inline-block;
                text-align: center;
                vertical-align: middle;
                //border: 1px solid #6c757d;
                border: none;

                font-size: 0.9rem;
                color: #6c757d;
                padding: 0;
                width: 12px;
                height: 18px;

                &:hover {
                    color: #fff;
                    background-color: #6c757d;
                    border-color: #6c757d;
                }

                &:focus {
                    border: none;
                    outline: none;
                }

                &:first-child {
                    border-bottom: 1px solid rgba(0, 0, 0, .25);
                }
            }
        }

        & .badge-factor {
            color: #fff;
            background-color: #3490dc;
            width: 56px;
            height: 36px;
            -webkit-box-align: center;
            -webkit-box-pack: center;
            display: -webkit-box;
        }
    }

    & .footer {
        background-color: #101621;
        display: flex;
        flex-direction: column;

        & .btn-place {
            height: 48px;
            text-transform: uppercase;
            border-radius: 0;
        }

        & .body {
            display: flex;
            flex-direction: column;
            position: relative;

            & .overlay {
                position: absolute;
                left: 0;
                bottom: 0;
                right: 0;
                top: 0;
                background: rgba(26,36,54,.87);
                display: flex;
                flex-direction: column;
                text-align: center;
                z-index: 10000;

                justify-content: center;
                align-content: center;

                & .message {
                    flex-grow: 1;
                    color: #ffed4a;

                    display: flex;
                    flex-direction: column;
                    justify-content: center;

                    & .title {
                        font-size: 18px;
                    }

                    & .content {
                        font-size: 12px;
                    }
                }

                & .buttons {
                    display: flex;
                    height: 48px;
                }
            }

            & .stake {
                text-align: center;
                border-radius: 4px;
                border: 1px solid #fff;
                width: 94px;
                background-color: #14171d;
                color: #fff;
            }

            & .stake:focus {
                background-color: #fff;
                color: #000;
            }

            & .btn-max {
                line-height: 1.4;
                margin-left: .25rem;
            }
        }
    }
}
