
// Fonts
@import url('https://fonts.googleapis.com/css?family=Nunito');

// Variables
@import 'variables';

// Bootstrap
@import '~bootstrap/scss/bootstrap';

@import 'betslip';
@import 'notification';
@import 'sidebar';

.modal-mask {
    position: fixed;
    z-index: 9998;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: table;
    transition: opacity 0.3s ease;
}

.modal-wrapper {
    display: table-cell;
    vertical-align: middle;
}

/*
 * The following styles are auto-applied to elements with
 * transition="modal" when their visibility is toggled
 * by Vue.js.
 *
 * You can easily play with the modal transition by editing
 * these styles.
 */

.modal-enter {
    opacity: 0;
}

.modal-leave-active {
    opacity: 0;
}

.modal-enter .modal-container,
.modal-leave-active .modal-container {
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
}


main {
    margin-top: 80px;
    padding-right: 312px;
    padding-left: 260px;
}

.results {
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;

    & .match {
        padding: .5rem;
        display: flex;
        flex-direction: column;
        border-top: 1px solid rgba(0, 0, 0, 0.125);

        & .league {
            padding-bottom: .25rem;
            font-style: oblique;
        }

        & .body {
            display: flex;

            & .status {
                color: #6c757d;
                text-align: center;
            }

            & .scores {
                padding: 0 .5rem;
            }

            & .teams {
                flex-grow: 1;
            }

            & .winner {
                font-weight: bold;
            }
        }
    }
}

.index > .header {
    border: 1px solid rgba(0, 0, 0, 0.125);
}

.div-striped div.div-striped-row:nth-of-type(odd) {
    background-color: rgba(0, 0, 0, .05);
}

// Remove top border
.index > .header ~ .header {
    border-top: none;
}

.flex-row.odd:nth-child(odd) {
    background-color: rgba(0, 0, 0, 0.05);
}

.flex-row.even:nth-child(even),
.transaction.even:nth-child(even) {
    background-color: rgba(0, 0, 0, 0.05);
}

.btn-cancel {
    display: inline-block;
    padding: 3px 8px;
    background-color: #f44336;
    color: #fff;
    border: none;

    &:hover {
        color: #fff;
        background-color: #ae1c17;
    }
}

.btn-wallet {
    outline: 0;
    border: 1px solid rgba(0, 0, 0, .5);
    border-radius: 18px;
    padding: .25rem .5rem !important;
}

.fs-06 {
    font-size: .6rem;
}

.fs-14 {
    font-size: 1.19rem;
}

.badge-notify {
    position: absolute;
    top: -5px;
    right: -5px;
    padding: 0.4em 0.4em 0.25em 0.4em;
}

.wallet-control {
    text-align: center;
    cursor: pointer;
}

.card.wager {
    & .btn-debug {
        right: 14px;
    }

    & .status {
        padding: 3px 5px;
        display: inline-flex;
        font-weight: 600;

        &.status_0, &.status_7 {
            background-color: #fbdb90;
        }

        &.status_1 {
            background-color: #4CAF50;
        }

        &.status_2 {
            background-color: #F08080;
        }

        &.status_3, &.status_4, &.status_5, &.status_6 {
            background-color: #ccc;
        }
    }

    &:hover {
        box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
    }
}

.btn,.btn-odd {
    &:disabled, &.disabled {
        opacity: .65;
        pointer-events: none;
    }
}

.market {
    margin-right: 4px;
    margin-bottom: 1px;
}

.market div {
    margin-right: 1px;
}

.btn-odd {
    display: inline-block;
    font-weight: 400;
    text-align: center;
    vertical-align: middle;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    font-size: .9rem;
    line-height: 1.4;
    //flex: 1 1 64px;
    min-width: 64px;
    color: #fff;
    background-color: #3490dc;
    border: 0;
    height: 100%;

    //&:focus {
    //    outline: none;
    //    box-shadow: none;
    //}

    &:hover {
        color: #fff;
        background-color: #227dc7;
        border-color: #2176bd;
    }

    &:focus, &.focus {
        box-shadow: 0 0 0 0.2rem rgba(82, 161, 225, 0.5);
    }

    &:not(:disabled):not(.disabled):active, &:not(:disabled):not(.disabled).active {
        color: #fff;
        background-color: #2176bd;
        border-color: #1f6fb2;
    }

    &:not(:disabled):not(.disabled):active:focus, &:not(:disabled):not(.disabled).active:focus {
        box-shadow: 0 0 0 0.2rem rgba(82, 161, 225, 0.5);
    }

    &.down {
        background-color: #e3342f;
    }

    &.up {
        background-color: #38c172;
    }
}

.return-to-top {
  position: fixed;
  bottom: 20px;
  right: 20px;
  display: none;
}

.navbar-nav .nav-item {
  padding-left: .4rem;
}

.dropdown-item-hover:hover {
    background-color: #f8f9fa;
}

.cursor-pointer {
    cursor: pointer;
}

.avatar {
  padding: 0 !important;
}

.dropdown-menu.scroll {
  height: 256px;
  overflow-y: auto;
}

/* Let's get this party started */
::-webkit-scrollbar {
  width: 7px;
  height: 7px;
}

/* Track */
::-webkit-scrollbar-track {
  border-radius: 0;
}

/* Handle */
::-webkit-scrollbar-thumb {
  border-radius: 0;
  background: #ccc;
}

::-webkit-scrollbar-thumb:window-inactive {
  background: #ccc;
}

.list-group-item:first-child {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
}

.list-group-item:last-child {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
}

.list-group-item {
  padding: .65rem .75rem;
}

.list-group a {
  border: none;
}

.match {
    & .button-arrow {
        width: 24px;
    }
}

.flex-direction {
    flex-direction: row;
}

.transactions {
    & .amount {
        width: 12rem;
    }
    & .balance {
        width: 16rem;
    }
}
.favorites {
    & .wrapper {
        &:hover {
            z-index: 1;
            background-color: #f8f9fa;
        }

        & .empty {
            padding: .65rem .75rem;
        }

        & .button, & .ttl {
            margin-right: .75rem;
        }

        & a.item {
            color: #495057;
            text-decoration: none;
            padding: .65rem 0 .65rem .75rem;
        }

        & a.button {
            display: none;
        }

        &:hover a.button {
            display: inline-block;
        }

        &:hover span.ttl {
            display: none;
        }
    }
}

/* Card Coin */
.card-coin {
    width: 24rem;
    margin: .25rem;

    & .card-body {
        & .wallet {
            height: 36px;
            color: #495057;
            background-color: #e9ecef;
            line-height: 2rem;
            padding-left: .5rem;

            & input {
                width: 100%;
                height: 36px;
                color: #495057;
                background-color: #e9ecef;
                border: none;
                cursor: pointer;
                text-align: center;

                &:focus {
                    outline: none;
                }
            }
        }
    }
}

@media(max-width: 1333px) {

}

@media(max-width: 1073px) {
    main {
        padding-right: 8px;
    }

    .betslip {
        top: 54px;
        right: -312px;
    }

    .betslip-open .betslip {
        right: 0;
        width: 100%;
    }

    .bet-card-wrapper {
        & .bet-card-container {
            & .bet-card-row {
                flex-wrap: wrap;

                & .bet-card-column {
                    flex-basis: 50%;
                }
            }
        }

        & .bet-card-match-container {
            display: block;
        }
    }
}

.betslip-open .betslip {
    right: -312px;
}

.sidebar-open .sidebar {
    left: -260px;
}

.betslip-open main {
    padding-right: 0;
}

.sidebar-open main {
    padding-left: 0;
}

@media (max-width: 815px) {
    .container, .container-fluid {
        padding: 0;
    }

    .match {
        & .mx-1 {
            margin-right: 0 !important;
            margin-left: 0 !important;
        }

        & .button-arrow {
            width: 100% !important;
            align-self: normal !important;

            & button {
                width: 100%;
            }
        }
    }

    main {
        padding-right: 8px;
        padding-left: 8px;
    }

    .sidebar {
        left: -260px;
    }

    .betslip {
        top: 54px;
        right: -312px;
    }

    .sidebar-open .sidebar {
        left: 0;
        width: 100%;
    }

    .betslip-open .betslip {
        right: 0;
        width: 100%;
    }

    .btn-odd {
        width: 100%;
        height: 52px;
    }

    .flex-direction {
        flex-direction: column;
    }

    .card-coin {
        width: 100%;
        margin-left: 0;
        margin-right: 0;
    }

    .notifications {
        position: fixed !important;
        top: 58px;
        bottom: 0;
        left: 0;
        right: 0;
        border: 0;
        border-radius: 0;
        width: 100%;
        height: 100%;
    }

    .bet-card-wrapper {
        & .bet-card-container {
            & .bet-card-row {
                flex-wrap: wrap;

                & .bet-card-column {
                    flex-basis: 33%;
                }
            }
        }

        & .bet-card-match-container {

        }
    }
}

@media(max-width: 555px) {
    .bet-card-wrapper {
        font-size: 12px;

        & .bet-card-container {
            & .bet-card-row {
                flex-wrap: wrap;

                & .bet-card-column {
                    flex-basis: 50%;
                }
            }
        }

        & .bet-card-match-container {
            display: block;
        }
    }
}

.league-row {
    padding: .5rem;

    a {
        display: block;
        text-decoration: none;
    }

    & .far:hover {
        font-weight: 900;
    }

    & .fas:hover {
        font-weight: normal;
    }

    &:hover {
        background-color: rgba(0, 0, 0, .075);
        cursor: pointer;
    }
}

[v-cloak] {
  display: none;
}

.live-search {
    position: absolute;
    border: 1px solid #d4d4d4;
    border-bottom: none;
    border-top: none;
    z-index: 99;

    div {
        padding: 10px;
        cursor: pointer;
        background-color: #fff;
        border-bottom: 1px solid #d4d4d4;
    }
}

.collapsing {
    transition: none !important;
}

.btn.no-focus:focus {
    outline: none !important;
    //border: none !important;
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
}

.pb-0-1 {
    padding-bottom: .1rem;
}
