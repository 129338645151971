.sidebar {
    position: fixed;
    width: 260px;
    top: 0;
    left: 0;
    bottom: 0;
    z-index: 20;
    background: #fff;
    overflow-y: auto;
    overflow-x: hidden;
    -webkit-box-shadow: 1px 0 20px rgba(0, 0, 0, 0.08);
    box-shadow: 1px 0 20px rgba(0, 0, 0, 0.08);

    & .sidebar-container {
        margin-top: 64px;
    }
}
