.notifications {
    padding: 0;
    margin: 0;
    width: 22rem;
    height: 24rem;

    & .content {
        display: flex;
        flex-direction: column;
        height: 100%;

        .header {
            display: flex;
            border-bottom: 1px solid rgba(0, 0, 0, 0.125);
            padding: .15rem .5rem;
            align-items: center;

            & .title {
                flex-grow: 1;
                font-weight: bold;
            }

            & .btn-read {
                color: #3490dc;

                display: inline-block;
                text-align: center;
                vertical-align: middle;
                -webkit-user-select: none;
                -moz-user-select: none;
                -ms-user-select: none;
                user-select: none;
                background-color: transparent;
                border: 0;
                padding: 0 0.55rem;
                font-size: 1.5rem;
                border-radius: 0;

                &:focus {
                    outline: none;
                    box-shadow: none;
                }

                &:hover, &:disabled {
                    color: rgba(0, 0, 0, .125);
                }
            }

            & .btn-view {
                font-size: 1.5rem;

                &:hover {
                    color: rgba(0, 0, 0, .125);
                }
            }
        }

        & .body {
            flex-grow: 1;

            display: flex;
            flex-direction: column;
            overflow-y: scroll;
            scrollbar-width: none;
            -ms-overflow-style: none;

            &::-webkit-scrollbar {
                width: 0;
                height: 0;
            }

            & .empty {
                display: flex;
                align-items: center;
                justify-content: center;
                height: 100%;
            }

            & .notification {
                display: flex;
                flex-direction: column;
                border-top: 1px solid rgba(0, 0, 0, 0.125);
                cursor: pointer;

                &:first-child {
                    border-top: 0;
                }

                &:last-child {
                    border-bottom: 1px solid rgba(0, 0, 0, 0.125);
                }

                &:hover {
                    background-color: rgba(0, 0, 0, 0.05);
                }

                & .notification-header {
                    display: flex;
                    padding: .25rem;
                    align-items: center;

                    & .title {
                        font-weight: bold;
                        flex-grow: 1;
                    }

                    & .created-at {
                        color:#6c757d;
                    }
                }

                & .notification-content {
                    padding: 0 .25rem .05rem .25rem;
                }

                & .notification-description {
                    padding: 0 .25rem .05rem .25rem;
                    text-align: right;
                }
            }
        }
    }
}
